@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";







































































































































































.proposed-assignment {
  &__details {
    &__title {
      margin-bottom: $size-s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq-m--mf {
        margin-bottom: 0;
      }
    }

    &__with-badge {
      .sas-badge {
        margin-left: $size-xs;
      }
    }
  }
}

.--margin-right {
  margin-right: 22px;
}

::v-deep .sas-button {
  i {
    @include mq_m__mf {
      margin-right: $size-xs;
    }
  }

  @include mq-m {
    margin-bottom: $size-xs;
  }

  &:disabled {
    opacity: 1;
  }
}
